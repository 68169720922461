.rsw-wrapper {
    min-height: 400px !important;
}
@keyframes stripes {
    from {
        background-position: 1rem 0;
   }
    to {
        background-position: 0 0;
   }
}
@keyframes rainbow {
    0% {
        background-color: var(--red);
   }
    20% {
        background-color: var(--orange);
   }
    40% {
        background-color: var(--yellow);
   }
    60% {
        background-color: var(--green);
   }
    80% {
        background-color: var(--blue);
   }
    100% {
        background-color: var(--indigo);
   }
}
@keyframes grow {
    from {
        width: 0%;
   }
    to {
        width: 100%;
   }
}
.progress-wrapper {
    padding-top: 25%;
}
.progress {
    height: 5px;
    overflow: hidden;
}
.progress-bar {
    height: 5px;
    width: 100%;
}
.loaded .progress-bar {
    animation: grow 3s ease, stripes 1s linear infinite, rainbow 3s ease infinite;
}
