/* forcing vertical scroll */
html {
  overflow-y: scroll;
}

/* footer */
.app-footer {
  color: silver;
  line-height: 0.1rem;
  margin-top: 8rem;
}
