/* Add this CSS in your CustomAuth component file or an external CSS file */
.body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    
    background: #138b55;
    color: #edf5f5;
}
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: linear-gradient(135deg, #00c6ff, #0072ff); */
     /* Background gradient */
    background:  #12764f; /* Background gradient */
    font-family: 'Poppins', sans-serif;
}

.auth-card {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.auth-card h2 {
    margin-bottom: 24px;
    color: #333333;
    font-weight: 600;
}

.auth-card .p-inputtext, .auth-card .p-password {
    width: 100%;
    margin-bottom: 16px;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #f7f7f7;
}

.auth-card .p-button {
    width: 100%;
    margin-bottom: 12px;
    padding: 14px 0;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.auth-card .p-button.p-button-success {
    background: #387a4bde;
    border: none;
    color: #ffffff;
}

.auth-card .p-button.p-button-secondary {
    background: #e0e0e0;
    border: none;
    color: #333333;
}

.auth-card .p-button.p-button-success:hover {
    background: #005bb5;
}

.auth-card .p-button.p-button-secondary:hover {
    background: #bdbdbd;
}

.auth-card a {
    color: #1e846e;
    text-decoration: none;
    font-size: 14px;
}

.auth-card a:hover {
    text-decoration: underline;
}
